<template>
  <div class="flex justify-between mt-5 mb-3">
    <Button label="Atras"
            icon="pi pi-arrow-left"
            class="mr-2 p-button-danger"
            @click="$router.go(-1)"
    />
  </div>

  <form @submit="onSubmit">
    <div class="mb-2">
      <label for="observaciones">Observaciones</label>
      <InputText
        v-model="model.observaciones"
        id="observaciones"
        type="text"
        class="form-control"
      />
      <div class="text-red-400">
        {{errors.observaciones}}
      </div>
    </div>

    <div class="mb-2">
      <label for="fechaInicio">Fecha Inicio</label>
      <Calendar id="fechaInicio" v-model="model.fechaInicio" autocomplete="off" class="w-full" />
      <div class="text-red-400">
        {{errors.fechaInicio}}
      </div>
    </div>

    <div class="mb-2">
      <label for="fechaFin">Fecha Fin</label>
      <Calendar id="fechaFin" v-model="model.fechaFin" autocomplete="off" class="w-full" />
      <div class="text-red-400">
        {{errors.fechaFin}}
      </div>
    </div>

    <div class="mb-2">
      <label for="responsable">Reponsable</label>
      <Dropdown v-model="model.responsable"
                id="responsable"
                :options="optionsUsers"
                optionLabel="fullName"
                optionValue="id"
                :filter="true"
                filterPlaceholder="Buscar"
                class="w-full"
      />
      <div class="text-red-400">
        {{errors.responsable}}
      </div>
    </div>

    <div class="mb-2">
      <label for="licitacionesId">Licitacion</label>
      <Dropdown v-model="model.licitacionesId"
                id="licitacionesId"
                :options="optionsLicitaciones"
                optionLabel="nombre"
                optionValue="id"
                :filter="true"
                filterPlaceholder="Buscar"
                class="w-full"
      />
      <div class="text-red-400">
        {{errors.licitacionesId}}
      </div>
    </div>

    <div class="mb-2">
      <Button label="Actualizar"
              icon="pi pi-plus"
              class="mr-2 p-button-success"
              type="submit"
      />
    </div>
  </form>

  <Toast/>
</template>

<script>
import { onMounted, ref } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import LicitacionesService from '@/apps/pharmasan/licitaciones/services/LicitacionesService'
import { useField, useForm } from 'vee-validate'
import { object, string, date, number } from 'yup'
import LicUsersService from '@/apps/pharmasan/licitaciones/services/LicUsersService'
import LicTareasService from '@/apps/pharmasan/licitaciones/services/LicTareasService'
import Swal from 'sweetalert2'

export default {
  name: 'TareasCrear',
  setup () {
    onMounted(() => {
      getOnLoad()
      getUsuarios()
    })
    // Variables
    const _LicitacionesService = ref(new LicitacionesService())
    const _LicUsersService = ref(new LicUsersService())
    const _LicTareasService = ref(new LicTareasService())
    const validationSchema = object().shape({
      observaciones: string().required().min(3),
      fechaInicio: date().required(),
      fechaFin: date().required(),
      responsable: number().required().min(3),
      licitacionesId: number().required().min(3)
    })
    const { values: model, errors, handleSubmit, handleReset } = useForm({
      validationSchema
    })
    useField('observaciones', null, { initialValue: '' })
    useField('fechaInicio', null, { initialValue: '' })
    useField('fechaFin', null, { initialValue: '' })
    useField('responsable', null, { initialValue: '' })
    useField('licitacionesId', null, { initialValue: '' })
    const optionsUsers = ref([])
    const optionsLicitaciones = ref([])
    const router = useRouter()
    const route = useRoute()

    // Methods
    const getUsuarios = () => {
      _LicUsersService.value.getUsers().then(({ data }) => {
        optionsUsers.value.push(...data)
      })
    }

    const onSubmit = handleSubmit((values) => {
      values.estado = 0
      _LicTareasService.value.updateTarea(route.params.id, values).then((data) => {
        if (data.status === 200) {
          Swal.fire({
            title: 'Exito',
            text: 'Tarea actualizada con éxito',
            icon: 'success',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'Ok'
          }).then((result) => {
            if (result.isConfirmed) {
              handleReset()
              router.go(-1)
            }
          })
        } else {
          console.log(data)
        }
      })
    })

    const getOnLoad = () => {
      _LicTareasService.value.findTarea(route.params.id).then(({ data }) => {
        model.observaciones = data.rows[0].observaciones
        model.fechaInicio = data.rows[0].fechaInicio
        model.fechaFin = data.rows[0].fechaFin
        model.responsable = data.rows[0].responsable
        model.licitacionesId = data.rows[0].licitacionesId
      }).catch((error) => {
        console.log(error)
      })

      _LicitacionesService.value.getAllLicitaciones().then(({ data }) => {
        optionsLicitaciones.value = data.rows.map(item => {
          return {
            id: item.id,
            nombre: item.nombre
          }
        })
      }).catch((error) => {
        console.log(error)
      })
    }

    return {
      model,
      errors,
      onSubmit,
      optionsUsers,
      optionsLicitaciones
    }
  }
}
</script>

<style scoped>

</style>
